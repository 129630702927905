<template>
  <div class="lsg-projects">
    <breadcrumbs-component :links="links"/>
    <div class="main__header toper">
      <div class="toper__item left">
        <h1>{{ title }}</h1>
      </div>
      <div class="toper__item right">
      </div>
    </div>
    <div class="main__content">
      <div class="lsg-projects-filter filter">
        <div class="lsg-projects-filter__q filter__row">
          <div class="form__item filter__item">
            <select name="status" id="status" v-model="projectFilter.status">
              <option :value="null">Все</option>
              <option value="СозданиеДЛ">СозданиеДЛ</option>
              <option value="СозданиеЛП">СозданиеЛП</option>
              <option value="СогласованиеЛП">СогласованиеЛП</option>
            </select>
          </div>
          <div class="form__item filter__item">
            <input type="text" v-model="filter.q" placeholder="Наименование"/>
          </div>
        </div>
      </div>
      <ApolloQuery
          :query="require('@/graphql/queries/AllPartners.gql')"
          :variables="{ page, perPage, filter }"
      >
        <template slot-scope="{ result: { error, data }, isLoading }">
          <!-- Error -->
          <div v-if="error" class="error apollo">Ошибка загрузки данных. Обратитесь к тех. поддержку</div>
          <!-- Result -->
          <div v-if="isLoading">
            Загрузка
          </div>
          <div v-else-if="data && data.allPartners && data.allPartners.length">
            <div
                v-for="(partner, index) in data.allPartners"
                :key="index"
            >
              <h3>{{ partner.fullname }}</h3>
              <div>
                <project-table :filter="getProjectFilter(partner.id)"></project-table>
              </div>
            </div>
          </div>
          <div v-else>Нет проектов</div>
          <div class="lsg-table-pagination">
            <div class="lsg-table-pagination-info">
              Показано
              <select v-if="_allPartnersMeta  >= 20" class="lsg-table-pagination-info__perpage" v-model="perPage" @change="page = 0">
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
                <option :value="200">200</option>
              </select>
              {{ 20 > _allPartnersMeta ? _allPartnersMeta + ' ' : '' }}из {{ _allPartnersMeta }}
            </div>
            <paginate
                :page-count="pageCount"
                :value="page + 1"
                :click-handler="setPage"
                prev-text="&lsaquo;"
                next-text="&rsaquo;"
                container-class="lsg-table-pagination-buttons">
            </paginate>
          </div>
        </template>
      </ApolloQuery>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import ProjectTable from './components/ProjectTable'
import BreadcrumbsComponent from '@/components/BreadcrumbsComponent'

export default {
  name: 'Projects',
  data: () => {
    return {
      title: 'Лизинговые проекты',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Лизинговые проекты'
        }
      ],
      page: 0,
      perPage: 50,
      filter: {
        q: ''
      },
      projectFilter: {
        status: null
      },
      _allPartnersMeta: 0
    }
  },
  components: {
    BreadcrumbsComponent,
    ProjectTable
  },
  computed: {
    getCurrentFilter () {
      let currentFilter = {}
      if (this.filter.q) currentFilter.q = this.filter.q

      return currentFilter
    },
    pageCount () {
      return Math.ceil(this._allPartnersMeta / this.perPage)
    }
  },
  apollo: {
    _allPartnersMeta () {
      return {
        query: gql`query($filter: PartnerFilter) {
          _allPartnersMeta(filter: $filter) {
            count
          }
        }`,
        variables () {
          return {
            filter: this.getCurrentFilter
          }
        },
        update: (data) => {
          this.$nextTick(() => {
            this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
          })
          return data._allPartnersMeta.count || 0
        }
      }
    }
  },
  methods: {
    setPage (page) {
      this.page = page - 1
    },
    goToPrevPage (e) {
      e.preventDefault()
      if (this.page !== 0) {
        this.page--
      }
    },
    goToNextPage (e, dataLength) {
      e.preventDefault()
      if ((dataLength < this.perPage) || (this._allPartnersMeta === (this.page + 1) * this.perPage)) return
      this.page++
    },
    isDisabled (dataLength) {
      if ((dataLength < this.perPage) || (this._allPartnersMeta === (this.page + 1) * this.perPage)) return true
      return false
    },
    getProjectFilter (partnerId) {
      let currentFilter = {}
      if (partnerId) currentFilter.partner_id = partnerId
      if (this.projectFilter.status) currentFilter.status = this.projectFilter.status

      return currentFilter
    }
  }
}
</script>
