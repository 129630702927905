<template>
  <ApolloQuery
    :query="require('@/graphql/queries/AllProjects.gql')"
    :variables="{ page: 0, perPage: 1000, filter, sortField: sort.field, sortOrder: sort.order }"
  >
    <template slot-scope="{ result: { error, data }, isLoading }">
      <!-- Error -->
      <div v-if="error" class="error apollo">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
      <!-- Result -->
      <div class="table__responsive">
        <table class="lsg-table result apollo">
          <thead>
            <tr>
              <th @click="sortingField('status')" :class="getSortableClass('status')">Статус</th>
              <th @click="sortingField('date')" :class="getSortableClass('date')">Дата</th>
              <th @click="sortingField('product')" :class="getSortableClass('product')">Продукт</th>
              <th>Менеджер</th>
            </tr>
          </thead>
          <!-- Loading -->
          <tbody v-if="isLoading">
            <tr>
              <td colspan="3">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else-if="data && data.allProjects && data.allProjects.length">
            <tr
              v-for="(product, index) in data.allProjects"
              :key="index"
              :class="{ 'success': product.status === 'СозданиеДЛ' }"
            >
              <td>
                <span :class="{ 'lsg-tag': true, 'tag-success': product.status === 'СозданиеДЛ' }">{{ product.status }}</span>
              </td>
              <td>
                {{ getDate(product.date) }}
              </td>
              <td>
                {{ product.product }}
              </td>
              <td>
                {{ product.manager && product.manager.name ? product.manager.name : '' }}
              </td>
            </tr>
          </tbody>
          <!-- No result -->
          <tbody v-else>
            <tr>
              <td colspan="3"><span>Нет результатов</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PartnerTable',

  props: {
    filter: Object
  },

  data: () => {
    return {
      sort: {
        field: 'date',
        order: 'desc'
      }
    }
  },

  methods: {
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    sortingField (columnName) {
      if (columnName === this.sort.field) this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc'
      this.sort.field = columnName
    },
    getSortableClass (columnName) {
      let currentClass = 'lsg-table-sort'
      if (columnName === this.sort.field) currentClass += ' sort-' + this.sort.order

      return currentClass
    }
  }
}
</script>
