<template>
  <ul class="lsg-breadcrumb">
    <li v-for="(link, index) in links" :key="index">
      <router-link v-if="link.route" :to="{name: link.route}">{{ link.name }}</router-link>
      <span v-else>{{ link.name }}</span>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'BreadcrumbsComponent',
  props: ['links']
}
</script>

<style lang="stylus" scoped>
.lsg-breadcrumb
  display flex
  list-style none
  margin 0
  padding 5px 30px
  flex-wrap wrap
  font-size 0.9em
  border-bottom 1px solid $graydark

  +below(768px)
    padding 5px 20px

  li
    color alpha($dark, 50%)

    a
      link($dark)

    &::after
      content '/'
      position relative
      display inline-block
      margin 0 10px

    &:last-child
      &::after
        display none

</style>
